/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "normalize.css"

import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'

const GlobalStyles = css`
  body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #2a233d;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin: 1.7em 0 0.9em;
  }

  h1 {
    font-size: 60px;
    line-height: 66px;
  }

  h2 {
    font-size: 34px;
    line-height: 42px;
  }
`;

const Main = styled.main`
  display: flex;
  min-height: calc(100vh - 150px);
`

const Layout = ({ children, onMouseLeave }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Main onMouseLeave={onMouseLeave}>
      <Global styles={GlobalStyles} />
      {children}
    </Main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
